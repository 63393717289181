export default {
  PAGE: {
    NEW_PERMISSION: "Nova Permissão",
    NEW_PROFILE: "Novo Perfil",
  },
  LABELS: {
    GROUPER_REGISTERED: "Agrupador Cadastrado | Agrupadores Cadastrados",
    PERMISSION_REGISTERED: "Permissão Cadastrada | Permissões Cadastradas",
    PROFILE_REGISTERED: "Perfil Cadastrado | Perfis Cadastrados",
    INPUT_NEW_AGREGATE: "Nome do Agrupador",
    USER_ASSOCIATE: "Associar Usuário",
    INPUT_NEW_PERMISSION: {
      PROFILE_NAME: "Nome do Perfil",
      PERMISSION_NAME: "Nome da Permissão",
      PROFILE_DESCRIPTION: "Descrição",
      MODULE: "Módulo | Módulos",
      GROUPER: "Agrupador | Agrupadores",
      PERMISSION: "Permissão | Permissões",
      ONLY_CRDC: "Visível somente para usuário CRDC",
      DEFAULT_PROFILE: "Perfil Defaut",
    },
    DELETE_MODAL: {
      GROUPER: {
        TITLE: "Deseja realmente excluir o agrupador?",
        SUBTITLE: "O agrupador será excluído",
      },
      PERMISSION: {
        TITLE: "Deseja realmente excluir a permissão?",
        SUBTITLE: "A permissão será excluída",
      },
      PROFILE: {
        TITLE: "Deseja realmente excluir o perfil?",
        SUBTITLE: "O perfil será excluído",
      },
    },
    TAB: {
      GROUPER: "Agrupador | Agrupadores",
      PERMISSION: "Permissão | Permissões",
      PROFILE: "Perfil | Perfis",
    },
    TABLE: {
      COLUMN_NAME: {
        PERMISSION: "Permissão",
        DESCRIPTION: "Descrição",
        ASSOCIATED_BUNDLER: "Agrupador Associado",
        ACTION: "Ação | Ações",
      },
    },
  },
  FORM: {
    ACTION: {
      ADD_GROUPER: "ADICIONAR AGRUPADOR",
      ADD_PERMISSION: "NOVA PERMISSÃO",
      ADD_PROFILE: "NOVO PERFIL",
      DELETE: "EXCLUIR",
      CANCEL: "CANCELAR",
    },
  },
  SUCCESS: {
    MSG001: "Incluido com sucesso",
    MSG002: "Excluído com sucesso",
  },
};
