export default class Params {
  constructor({ pagination = null, id = null, filter = null }) {
    this.id = id;
    this.sortBy = pagination?.sortBy;
    this.descending = pagination?.descending;
    this.sortDir = pagination?.sortDir;
    this.page = pagination?.page;
    this.rowsPerPage = pagination?.rowsPerPage;
    this.totalPages = pagination?.totalPages;
    this.isValid = true;
    this.setFilter(filter);
  }

  setId(id) {
    this.id = id;
  }

  setProp(prop, value) {
    const idProps = ["id"];
    const paginationProps = [
      "sortBy",
      "descending",
      "sortDir",
      "page",
      "rowsPerPage",
      "totalPages",
    ];
    const filterProps = ["filter"];
    const notAllowedProps = [idProps, paginationProps, filterProps].flat();
    if (notAllowedProps.includes(prop)) {
      throw new Error(`Property "${prop}" not allowed`);
    }
    this[prop] = value;
  }

  setFilter(filter) {
    const pFilter = {};
    Object.keys(filter || {}).forEach((prop) => {
      if (!filter[prop]) {
        return;
      }
      if (typeof filter[prop] === "object") {
        /** Handling Dropdown */
        const value = [filter[prop].value, filter[prop].id].find(
          (val) => val !== undefined
        );
        if (value || typeof value === "boolean") {
          pFilter[prop] = value;
          return;
        }
        if (value === null) {
          return;
        }
        /** Handling Date Range */
        const { from, to } = filter[prop];
        if (from) pFilter[`${prop}From`] = from;
        if (to) pFilter[`${prop}To`] = to;
        if (from !== undefined && to !== undefined) return;
      }
      /** Handling Boolean */
      if (typeof filter[prop] === "boolean") {
        pFilter[prop] = filter[prop];
        return;
      }
      /** Handling Number */
      if (typeof filter[prop] === "number") {
        pFilter[prop] = filter[prop];
        return;
      }
      /** Handling Text */
      if (typeof filter[prop] === "string" && filter[prop].length >= 3) {
        pFilter[prop] = filter[prop];
        return;
      }
      this.isValid = false;
    });
    this.filter = Object.keys(pFilter).length ? pFilter : null;
  }

  getId() {
    return this.id;
  }

  getProp(prop) {
    return this[prop];
  }

  getParams() {
    const params = {};
    if (this.sortBy) params.sortBy = this.sortBy;
    params.descending = this.descending;
    this.descending ? (params.sortDir = "desc") : (params.sortDir = "asc");
    if (this.page) params.page = this.page;
    if (this.rowsPerPage) params.rowsPerPage = this.rowsPerPage;
    if (this.totalPages) params.totalPages = this.totalPages;
    // if (Object.keys(this.filter || {}).length) params.filter = this.filter
    return params;
  }

  getPageParams() {
    const params = {};
    params.sortBy = this.sortBy;
    params.descending = this.descending;
    params.page = this.page;
    params.rowsPerPage = this.rowsPerPage;
    params.sortDir = this.sortDir;
    params.totalPages = this.totalPages;
    return params;
  }
}
