export const add = (name, payload = null) => {
  let event;
  if (payload) {
    event = CustomEvent(name, { detail: payload });
  } else {
    event = new Event(name);
  }
  window.dispatchEvent(event);
};

export const listen = (name, callback) => {
  window.addEventListener(name, callback);
};
