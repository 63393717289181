/* eslint-disable no-dupe-keys */
import PROFILE from "./accessManagement/profile/profile";
import SERVICE_PROVIDER from "./accessManagement/serviceProvider";
import FormAddress from "./forms/address";
import FormValidations from "./forms/validations";

export default {
  /** Here are page messages, usually very short and simple */
  PROFILE,
  SERVICE_PROVIDER,
  PAGE: {
    HOME: "Início",
    PRIVACY_POLICY: "Política de Privacidade",
    CONTRACTOR: "Contratante | Contratantes",
    CONTRACTOR_EDIT: "Editar",
    CONTRACTOR_CREATE: "Incluir Contratante",
    CONTRACTOR_VIEW: "Visualizar",
    CLIENT: "Cliente | Clientes",
    PEOPLE: "Pessoa | Pessoas",
    USER: "Usuário | Usuários",
    USER_INVITE: "Convidar Usuários",
    USER_GROUPS: "Grupo de Usuários",
    PROFILE: "Perfil | Perfis",
    FIRP: "Gestão de FIRP",
    WORKFLOW: "Gestão de Workflow",
    BOOKKEEPING: "Escrituração",
    CLAUSE_LIBRARY: "Biblioteca de Cláusulas",
    TYPE_OF_DOCUMENTS: "Tipo de Documento | Tipo de Documentos",
    TEMPLATES: "Template | Templates",
    DOCUMENT_MANAGEMENT: "Gerir Envelope | Gerir Envelopes",
    STORAGE: "Armazenamento",
    MESSENGER: "Mensageria",
    MONITORING_NFE_CTE: "Monitoramento NFE/CTE",
    MONITORING_GROUPS: "Grupo de Monitoramento | Grupos de Monitoramento",
    DATA_ANALYSIS_SANITATION: "Analise de Dados/Saneamento",
    DOCUMENT: "Documento | Documentos",
    DOCUMENT_ASSET: "Manual | Manuais",
    TERMS_OF_USE: "Termos de Uso",
    MY_ACCOUNT: "Minha Conta",
    HELP: "Ajuda",
    TUTORIAL: "Tutoriais",
    CHAT_ONLINE: "Chat Online",
    FAQS: "FAQs",
    QUESTIONS_AND_SUGGESTIONS: "Dúvidas e Sugestões",
    LOGOUT: "Sair",
    INVITE_USER: "Convidar Usuários",
    BACK_HOME: "Voltar para a página inicial",
    SERVICE_PROVIDER: "Prestador de Serviço | Prestadores de Serviços",
    SERVICE_PROVIDER_CREATE: "Incluir/ Associar Prestador de Serviço",
  },
  /** Here are the specific messages for DIVs */
  DIV: {
    LOGIN: {
      YEAR: "© 2022 ",
      CRDC: "CRDC",
    },
    FOOTER: {
      USE_TERMS: "Termos de Uso",
      PRIVACY_POLICY: "Politica de Privacidade",
      CRDC: "CRDC",
      COPYRIGHT: "Todos os direitos reservados",
      MOBILE: {
        TEXT: {
          PART_1: "Tecnologia que simplifica a jornada de",
          PART_2: "crédito dos agentes financeiros.",
        },
        SITE: "Site da CRDC",
      },
    },
  },
  /** Here are the specific messages for forms */
  FORM: {
    /** For buttons or form actions */
    ACTION: {
      ACCEPT: "Aceitar",
      BACK: "Voltar",
      BLOCK: "Bloquear",
      CANCEL: "Cancelar",
      CLOSE: "Fechar",
      CONCLUDE: "Concluir",
      CONFIRM: "Confirmar",
      CONTINUE: "Continuar",
      CREATE: "Incluir",
      SEND_DOCUMENT: "Enviar Documento | Enviar Documentos",
      INVITE: "Convidar",
      DETAIL: "Detalhes",
      DOWNLOAD: "Download",
      DELETE: "Excluir",
      DISABLE: "Inativar",
      DISASSOCIATE: "Desassociar",
      USER_DISASSOCIATE: "Desassociar Usuário",
      PROFILE_DISASSOCIATE: "Desassociar Perfil",
      USER_ASSOCIATE: "Associar Usuário",
      PROFILE_ASSOCIATE: "Associar Perfil",
      EDIT: "Editar",
      ENABLE: "Ativar",
      FORGOT_PASSWORD: "Esqueci minha senha",
      LOGIN: "Entrar",
      NO: "Não",
      NOVO: "Novo",
      PASS: "Avançar",
      TO_SIGN: "Assinar",
      SHARE: "Compartilhar",
      HISTORIC: "Histórico | Históricos",
      SAVE: "Salvar",
      REPORT: "Relatório",
      REFUSE: "Recusar",
      UNBLOCK: "Desbloquear",
      VIEW: "Visualizar",
      YES: "Sim",
      USER_GROUPS: "Grupo de Usuários",
      UPLOAD: "Fazer Upload",
      DELETE_USER: "Excluir Usuário",
      USER_EDIT: "Editar Usuário",
      UNAVAILABLE_ACTION: "No momento, esta ação não está disponível.",
      ACCEPT_POLICY: "Li e concordo com a Política de Privacidade",
      ACCEPT_TERM: "Li e concordo com os termos de uso",
      SERVICE_PROVIDER: "Prestador de serviço | Prestadores de serviços",
      SERVICE_PROVIDER_ADD: "Incluir / Associar Prestador de serviço",
      CREATE_ACCOUNT: "Cadastre-se",
      ANALYSIS_DOCUMENT: "Analisar Documento",
    },
    /** Validation messages */
    VALIDATION: FormValidations,
    ADDRESS: FormAddress,
  },
  /** Here are the specific messages for tables */
  TABLE: {
    LOADING: "Carregando dados...",
    NO_DATA: "Não há dados",
    NO_RESULT: "Sem resultados encontrados na nossa base",
    ROWS_PER_PAGE: "Exibir por Página",
    PAGINATION: "{start} - {end} de {totalRows}",
  },
  /** For labels */
  LABEL: {
    ACTION: "Ação | Ações",
    PENDING: "Pendente | Pendentes",
    EXPIRED: "Expirado | Expirados",
    WAITING_ANOTHER: "Aguardando por outro | Aguardando por outros",
    EXPIRING_SOON: "Expirando em breve",
    CONCLUDED: "Concluído | Concluídos",
    ADDRESS: "Endereço | Endereços",
    ADDRESS_TYPE: "Tipo de Endereço",
    CONTACT: "Contato | Contatos",
    CONTACT_ADD: "Incluir Contato | Incluir Contatos",
    CONTACT_TYPE: "Tipo de Contato",
    SENDED_AT: "Enviado em",
    EXPIRED_AT: "Expira em",
    PROGRESS: "Progresso",
    RESPONSIBLE: "Responsável | Responsáveis",
    AGENT: "Representante Legal | Representantes Legais",
    BILLING_ACCOUNT: "Conta Billing | Contas Billing",
    CELL_PHONE: "Celular | Celulares",
    CITY: "Cidade | Cidades",
    COLUMN: "Coluna | Colunas",
    COMPLEMENT: "Complemento | Complementos",
    CONFIRM_PASSWORD: "Confirmar senha | Confirmar senhas",
    CONTRACT: "Contrato | Contratos",
    CPF: "CPF | CPFs",
    CNPJ: "CNPJ | CNPJs",
    CPF_CNPJ: "CPF/CNPJ",
    CODE_VERIFICATION: "Código de Verificação",
    DISABLED: "Inativo | Inativos",
    DOCUMENT: "Documento | Documentos",
    PENDING_SIGN: "Está solicitando a sua validação e assinatura do documento:",
    PRE_SIGN_DOCUMENT: {
      TITLE1: "Você recebeu um",
      TITLE2: "documento para análise e assinatura.",
      SUBTITLE1: "Olá! Estamos enviando o ",
      SUBTITLE2: " para sua análise e assinatura. ",
    },
    EMAIL: "E-mail | E-mails",
    ENABLED: "Ativo | Ativos",
    CONTRATANTE: "Contratante | Contratantes",
    CONTRATANTE_CNPJ: "CPF/CNPJ Contratante | CPF/CNPJ Contratantes",
    CONSULTOR: "Consultora | Consultoras",
    CONSULTOR_CNPJ: "CNPJ do Consultor",
    GESTOR: "Gestor | Gestores",
    GESTOR_CNPJ: "CNPJ do Gestor",
    ADMINISTRADOR: "Administrador",
    ADMINISTRADOR_CNPJ: "CNPJ do Administrador",
    CUSTODIANTE: "Custodiante",
    CUSTODIANTE_CNPJ: "CNPJ do Custodiante",
    IE: "Inscrição Estadual | Inscrições Estaduais",
    IM: "Inscrição Municipal | Inscrições Municipais",
    LAST_NAME: "Sobrenome | Sobrenomes",
    SEND: "Enviar",
    NAME: "Nome | Nomes",
    NEIGHBORHOOD: "Bairro | Bairros",
    NO: "Não",
    NO_RESULT: "Sem resultados encontrados na nossa base",
    NUMBER: "Nº | Nºs",
    NUMBER_TEXT: "Número",
    CONTRACTOR_TYPE: "Tipo de Contratante | Tipo de Contratantes",
    PASSWORD: "Senha | Senhas",
    BASIC_DATA: "Dado Básico | Dados Básicos",
    PHONE: "Telefone | Telefones",
    PROFILE_TYPE: "Perfil | Perfis",
    SELECT: "Selecione",
    STATUS: "Status",
    STREET: "Logradouro | Logradouros",
    CONTRACTOR: "Contratante | Contratantes",
    TRADE_NAME: "Nome/Razão Social | Nomes/Razões Sociais",
    UF: "UF | UFs",
    USERNAME: "Usuário | Usuários",
    ZIPCODE: "CEP | CEPs",
    INSERT_SUBSCRIBERS: "1. Assinantes",
    FIRST_UPLOAD_FILE: "2. Documentos",
    CLICK_TO_UPLOAD_DOCUMENTNG: "Selecione um documento para fazer o upload",
    CLICK_TO_UPLOAD_DOCUMENTNG_SUBTITLE:
      "Ou se preferir, arraste e solte o arquivo aqui",
    UPLOAD_URL: "Ou faça o upload à partir de uma URL",
    SEQUENCE_SUBSCRIBERS: "Seguir ordem de assinantes",
    TUTORIAL: "Tutorial",
    URL: "URL",
    HTTPS: "https://",
    COMPLETE: "Completo",
    IN_PROGRESS: "Em andamento",
    DOCUMENT_DATA: "Dados do documento",
    DOCUMENT_SUBSCRIBERS: "Dados dos Assinantes",
    DOCUMENT_LOGS: "Logs de Atividade",
    DOCUMENT_NAME: "Nome do Documento",
    SHARE_DOCUMENT: "Compartilhar Documento",
    SHARE_DOCUMENT_MESSAGE:
      "Você pode compartilhar por e-mail o documento anexado",
    SENT_BY: "Enviado por",
    POST_DATE: "Data da Postagem",
    EMAIL_TEXTAREA: "Texto do E-mail",
    CONTRACTOR_ACTION: {
      LABEL: {
        JUSTIFICATION: "Motivo",
      },
      ACTION: { ENABLE: "ativar", DISABLE: "inativar" },
      TEXT_1: "Deseja realmente ",
      TEXT_2: "este contratante?",
      JUSTIFICATION_TEXT: "Informe abaixo o motivo da ",
      JUSTIFICATION_ACTION: { ENABLE: "Ativação", DISABLE: "Inativação" },
    },
    USER_GROUP_ACTION: {
      LABEL: {
        JUSTIFICATION: "Motivo",
      },
      ACTION: { ENABLE: "ativar", DISABLE: "inativar" },
      TEXT_1: "Deseja realmente ",
      TEXT_2: "este usuário?",
      TEXT_3: " de ",
      JUSTIFICATION_TEXT: "Informe abaixo o motivo da ",
      JUSTIFICATION_ACTION: { ENABLE: "ativação", DISABLE: "inativação" },
    },
    USER_ACTION: {
      LABEL: {
        JUSTIFICATION: "Motivo",
      },
      ACTION: {
        ENABLE: "ativar",
        DISABLE: "inativar",
        DELETE: {
          TITLE: "Deseja realmente desassociar o usuário?",
          SUBTITLE: "O usuário será desassociado",
        },
      },
      TEXT_1: "Deseja realmente ",
      TEXT_2: "este Usuário?",
      TEXT_3: " de ",
      JUSTIFICATION_TEXT: "Informe abaixo o motivo da ",
      JUSTIFICATION_ACTION: { ENABLE: "Ativação", DISABLE: "Inativação" },
    },
    BACK_TO_LIST: "Voltar à lista",
    CONTRACTOR_ADD: "Incluir Contratante",
    CONTRACTOR_ADD_MODULE: "Módulo: Gestão de Acessos",
    PENDING_INVITE: "Convite Pendente | Convites Pendentes",
    RESEND_INVITE: "Reenviar convite",
    REMOVE_INVITE: "Remover convite",
    INVITE_ATENTION_TEXT: "Os usuários convidados irão aparecer aqui.",
    GO_HOME: "Go Home",
    INDICATORS: {
      OTHERS: "AGUARDANDO POR OUTROS",
      EXPIRATION_SOON: "EXPIRANDO EM BREVE",
      COMPLETED: "PROCESSOS CONCLUÍDOS",
      PENDING: "DOCUMENTOS PENDENTES",
    },
    SIGN_DOCUMENT: {
      DELETE_DOCUMENT: "Deseja realmente excluir este documento?",
      DELETE_DOCUMENT_ATENTION:
        "O Documento selecionado será excluído permanentemente",
    },
    FAST_PASS: "Acesso Rápido",
    SEND_DOCUMENT_MODULE: "Módulo: Gestão de Documentos",
    DOCUMENT_MANAGEMENT: "Gerir Envelopes",
    DOCUMENT_MANAGEMENT_MODULE: "Módulo: Gestão de Documentos",
    USER_GROUPS_MODULE: "Módulo: Gestão de Acessos",
    RECENT_RECORDS: "Registros recentes",
    TOTALIZERS: "Totalizadores",
    NOME_GESTOR: "Nome do Gestor",
    NOME_CUSTODIANTE: "Nome do Custodiante",
    NOME_ADMINISTRADOR: "Nome do Administrador",
    NOME_CONSULTOR: "Nome do Consultor",
    DELETE_PERSON: "Deseja realmente excluir este ",
    DELETE_ATENTION: {
      TEXT_1: "O ",
      TEXT_2: " selecionado será excluído permanentemente",
    },
    DISASSOCIATE_PERSON: {
      TEXT: "Deseja realmente desassociar o perfil ao usuário?",
      SUB: "Selecione o perfil que deseja desassociar",
    },
    SHOW_CONTACTS_HERE: "Os contatos inseridos irão aparecer aqui",
    INFO_TEXT_SIGN_DOC:
      "Arraste e solte o campo de assinatura no documento abaixo. Este elemento indicará o espaço a ser assinado posteriormente pelos assinantes informados na etapa anterior",
    CNPJ_SERVICE_PROVIDER: "CNPJ Prestador",
    SERVICE_PROVIDER: "Prestador de Serviço",
    SERVICE_PROVIDER_TYPE: "Tipo Prestador",
    SERVICE_PROVIDER_ADD: "Incluir/ Associar Prestador de Serviço",
    SERVICE_PROVIDER_TYPE: "Tipo Prestador | Tipo Prestadores",
    CNPJ_SERVICE_PROVIDER: "CNPJ Prestador | CNPJ Prestadores",
    SEND_FOR: "Enviado por:",
    SEND_DATE: "Data do envio",
    SIGN_OF: "Assinatura de: ",
    WAITING_SIGN: "Aguardando Assinatura",
  },
  /** Here are very specific messages for certain components */
  COMPONENT: {
    /** For organisms */
    AUTH: {
      LOGIN: {
        LOGIN: "Login",
        FORM: {
          TITLE: "LOGIN",
          WELCOME: "Bem-vindo. Entre com os dados abaixo",
        },
      },
      SIGN_UP: {
        INTRO:
          "Tecnologia que simplifica a jornada de crédito dos agentes financeiros.",
        SUB_INTRO: `A Plataforma CRDC facilita os processos operacionais de validação, gestão
          e inteligência dos dados das operações de crédito para sua empresa.`,
        SIGN_UP: "Cadastrar",
        RULES_TOOLTIP: `Regras para criação do usuário:
        - Tamanho mínimo 3 caracteres,
        - Letra maiúscula e/ou,
        - Letra minúscula e/ou,
        - Número.`,
        RULES_TOOLTIP_RESET: "Insira o nome de usuário.",
      },
    },
    MODAL: {
      HISTORIC: {
        LABEL: {
          HISTORIC: "HISTÓRICO",
        },
      },
    },
    PASSWORD: {
      RESET: "Enviar código",
      RULES_TOOLTIP_RESET: "Insira o nome de usuário.",
      RULES_TOOLTIP: `Regras para criação de senha:
      - Tamanho mínimo 8 dígitos,
      - Letra maiúscula,
      - Letra minúscula,
      - Número,
      - Caracter Especial.`,
    },
  },
  /** Here are the messages for notifications */
  NOTIFICATION: {
    WELCOME: "Bem vindo {name}",
    LOGOUT: "Você saiu da aplicação!",
    PROFILE_CHANGED: 'Seu perfil mudou para "{name}"',
    TOKEN_EXPIRATION: "Token expirado",
    REQUEST_NEW_INVITE: "Solicite um novo convite ao administrador...",
  },
  /** Here are the messages for loaders */
  LOADER: {
    WELCOME: "Preparando o ambiente...",
    LOGOUT: "Saindo...",
    PROFILE_CHANGE: "Seus dados de perfil estão sendo carregados...",
    TOKEN_VALIDATION: "Validando o token...",
    SEND_DOCUMENT: "Enviando documento para ser assinado...",
    SENDING_INVITE: "Enviando o convite...",
    USER_CREATE: "Validando as informações e criando seu usuário...",
    USER_LIST: "Carregando a lista de usuários...",
    DATAS_CONTRACTOR: "Carregando as informações do contratante...",
    FETCH_DOCUMENT: "Carregando o documento...",
    SIGN_DOCUMENT: "Assinando o documento...",
  },
  SUCCESS: {
    MSGS01: "Contratante criado com sucesso!",
    MSGS02: "Contratante atualizado com sucesso!",
    MSGS03: "Status atualizado com sucesso!",
    MSGS04: "Usuário desassociado com sucesso!",
    MSGS05: "Documento enviado com sucesso para assinatura!",
    MSGS06: "Perfil do Usuário desassociado com sucesso!",
    MSGS07: "Documento assinado com sucesso!",
    MSGS08:
      "Você receberá uma cópia do documento após todos os participantes assinarem.",
    MSGS09: "Usuário atualizado com sucesso!",
    MSGS10: "Documento excluído com sucesso!",
    MSGS011: "Convite enviado com sucesso!",
    MSGS012: "Convite removido!",
    MSGS013: "Convite reenviado!",
    MSGS014: "Documento enviado!",
    MSGS015: "Excluído com sucesso!",
    MSGS016: "Usuário ja existente, perfil associado com sucesso!",
  },
  ALERT: {
    MSGAN001: "Informe o motivo da alteração de Status.",
    MSGA002: "CNPJ já cadastrado.",
    MSGA003: "CPF já cadastrado.",
    MSGA005: "Confirma a exclusão?",
    MSGA006: "Confirma o cancelamento da operação?",
    MSGA012:
      "O CPF informado está bloqueado no sistema e não poderá ser recadastrado.",
    MSGA014: "Você já possui a consulta como DEVEDOR no seu perfil.",
    MSGA017:
      "Um código de verificação foi enviado para seu e-mail para que prossiga com a alteração da sua senha, caso tenha cadastro!",
    MSGA018: "Confirma a desassociação do usuário?",
    MSGA019: "Cadastro finalizado com sucesso!",
  },
  ERROR: {
    MSGEN002: "As senhas são diferentes.",
    MSGEN003: "O mês/ano deve ser menor ou igual ao atual.",
    MSGE014: "Período inválido. Não pode ultrapassar {days} dias.",
    MSGE015: "A {name} (De) deve ser menor ou igual a {name} (Até)",
    MSGE016: "O {name} (De) deve ser menor ou igual ao {name} (Até)",
    MSGE019: "O período máximo para consulta retroativa é de até {year} anos",
    MSGE028:
      "Representante Legal não autorizado para cadastro da empresa CNPJ {val}",
    MSGE039:
      "Somente é permitido arquivo com a extensão PDF e com tamanho menor que {size}",
    MSGE040: "Campos obrigatórios não preenchidos ou inválidos",
    MSGE041: "Este usuário ja foi convidado!",
    MSGE042: "Convite não pode ser removido!",
    MSGE043: "Convite não pode ser reenviado!",
    MSGE044: "Documento não pode ser assinado!",
  },
  PAGE403: {
    OPS: "Oops. Você não pode acessar essa página...",
  },
  PAGE404: {
    OPS: "Oops. Não tem nada aqui...",
  },
  LOADING: {
    DEFAULT: "Processando...",
  },
};
