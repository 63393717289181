import axios from "axios";

/**
 * Create an Axios instance
 * @param {import("axios").AxiosRequestConfig} config
 * @returns {import("axios").AxiosInstance}
 */
function createApi(config) {
  return axios.create(config);
}

export default createApi;
