export default {
  LABELS: {
    DELETE_MODAL: {
      TITLE: "Deseja realmente desassociar o prestador?",
      SUBTITLE: "O prestador será desassociado",
    },
  },
  FORM: {
    ACTION: {
      DESASSOCIATE: "DESASSOCIAR",
    },
  },
  SUCCESS: {
    MSG001: "Prestador de Serviço Desassociado com sucesso! ",
    MSG002: "Prestador de Serviço atualizado com sucesso!",
    MSG003: "Prestador de Serviço cadastrado com sucesso!",
  },
};
