import { getAccessModules } from "../storage";
import { Auth, Amplify } from "aws-amplify";
import { config } from "../amplify";

Amplify.configure(config());

export const isAccessAllowed = (permissions) => {
  if (!permissions || !permissions.length) return false;

  return permissions.some((permission) => {
    const moduleApplication = permission.split(":")[0];
    const permissionApplication = permission.split(":")[1];

    const module = getAccessModules().find(
      (modulo) => moduleApplication === modulo.chave
    );

    if (module) {
      return module.permissoes?.some(
        (permission) => permission == permissionApplication
      );
    }
    return false;
  });
};

const validateAmplify = async () => {
  try {
    await Auth.currentAuthenticatedUser();
    return true;
  } catch (e) {
    return false;
  }
};

export const guard = (router) => {
  router.beforeResolve(async (to, from, next) => {
    const validateSession = await validateAmplify();
    const requiredRules = to.meta?.rules;
    const isRequiresAuth = to.meta?.requiresAuth;
    const isMicrofrontend = to.meta?.microfrontend;

    if (!isRequiresAuth) {
      return next();
    }

    if (validateSession) {
      if (!requiredRules || isMicrofrontend) {
        return next();
      }

      if (isAccessAllowed(requiredRules)) {
        return next();
      }

      return next({ path: "/not-found" });
    } else {
      window.location = `${window.location.origin}/auth/login`;
    }
  });
};
