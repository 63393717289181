export const formatPhone = (val) => {
  if (!val) return null;
  const onlyNumCellPhone = val.replace(/\D+/g, "");
  const regexp = /^(\d{2})\D*(\d{5}|\d{4})\D*(\d{4})$/;
  return onlyNumCellPhone.replace(regexp, "($1)$2-$3");
};

export const formatCPF = (val) => {
  if (!val) return null;
  const onlyNumber = val.replace(/\D+/g, "");
  return onlyNumber.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
};

export const formatCNPJ = (val) => {
  if (!val) return null;
  const onlyNumber = val.replace(/\D+/g, "");
  return onlyNumber.replace(
    /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
    "$1.$2.$3/$4-$5"
  );
};

export const formatDate = (val) => {
  if (!val) return null;

  const newDate = new Date(val);
  const hours = newDate.getUTCHours();
  const minutes = newDate.getUTCMinutes();

  return newDate.toLocaleDateString("pt-BR") + " | " + hours + "h" + minutes;
};

export const onlyNumber = (val) => {
  if (!val) return null;
  return val.replace(/\D+/g, "");
};

export const isValidOnlyLetters = (val) => {
  if (!val) return null;
  const regex = /[0-9\-!@#$%^&*()_+=[\]{};:"\\|,.<>/?¨]/;
  return !regex.test(val);
};

export const isValidEmail = (val) => {
  const emailPattern =
    /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/;
  return emailPattern.test(val);
};

export const concatPermissions = (arrayObjPermissions) => {
  if (!arrayObjPermissions) return null;

  const newArrayPermissions = [];
  arrayObjPermissions.forEach((permissions) => {
    if (newArrayPermissions.length > 0) {
      newArrayPermissions.concat(...permissions);
    } else {
      newArrayPermissions.push(...permissions);
    }
  });
  return newArrayPermissions;
};

export const isValidURL = (str) => {
  var a = document.createElement("a");
  a.href = str;
  return a.host && a.host != window.location.host;
};

export const formatBytes = (a, b = 2) => {
  if (!+a) return "0 Bytes";
  const c = 0 > b ? 0 : b,
    d = Math.floor(Math.log(a) / Math.log(1024));
  return `${parseFloat((a / Math.pow(1024, d)).toFixed(c))} ${
    ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]
  }`;
};

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () =>
      resolve(reader.result.replace("data:application/pdf;base64,", ""));
    reader.onerror = (error) => reject(error);
  });

export const convertToBase64 = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export default {
  formatPhone,
  formatCPF,
  formatCNPJ,
  formatDate,
  onlyNumber,
  isValidOnlyLetters,
  isValidEmail,
  concatPermissions,
  isValidURL,
  formatBytes,
  toBase64,
  convertToBase64,
};
