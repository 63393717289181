import { cnpj as cnpjValidator, cpf as cpfValidator } from "cpf-cnpj-validator";
import FormValidations from "../i18n/pt-Br/forms/validations";
/**
 * Required Rules
 */
export const required = (val) => {
  return (
    !!val ||
    (typeof val === "number" && val === 0) ||
    (typeof val === "boolean" && (val === true || val === false)) ||
    FormValidations.REQUIRED
  );
};

export const requiredRules = [required];

/**
 * Search Rules
 */
export const searchSizeRequired = (val) => {
  if (!val) return true;
  const regexp = /^.{3,}$/;
  return regexp.test(val) || FormValidations.SEARCH.SIZE;
};

/**
 * CPF Rules
 */
export const cpfPattern = (val) => {
  if (!val) return true;
  const regexp = /^(\d{3}).?(\d{3}).?(\d{3})-?(\d{2})$/;
  const isValid = regexp.test(val) && cpfValidator.isValid(val);
  return isValid || FormValidations.CPF.INVALID;
};

export const cpfRules = [cpfPattern];

/**
 * CNPJ Rules
 */
export const cnpjPattern = (val) => {
  if (!val) return true;
  const regexp = /^(\d{2}).?(\d{3}).?(\d{3})\/?(\d{4})-?(\d{2})$/;
  const isValid =
    regexp.test(val) && cnpjValidator.isValid(val) && val.length === 14;
  return isValid || FormValidations.CNPJ.INVALID;
};

export const cnpjRules = [cnpjPattern];

/**
 * Document Rules
 */

export const documentPattern = (val) => {
  if (!val) return true;
  /** Here I am validating to see if the value is a CPF */
  const isCpf = [];
  cpfRules.forEach((rule) => {
    isCpf.push(rule(val));
  });
  /** Here returns the first CPF error message, if it exists */
  const cpfError = isCpf.find((v) => v !== true);
  /** Here I am validating to see if the value is a CNPJ */
  const isCnpj = [];
  cnpjRules.forEach((rule) => {
    isCnpj.push(rule(val));
  });
  /** Here returns the first CNPJ error message, if it exists */
  const cnpjError = isCnpj.find((v) => v !== true);
  /**
   *  If you don't find any errors in one of the validations,
   *  I assume it can be considered a success
   */
  const isValid = !cpfError || !cnpjError;
  return isValid || FormValidations.DOCUMENT.INVALID;
};

export const documentRules = [documentPattern];

/**
 * E-mail Rules
 */
export const emailPattern = (val) => {
  if (!val) return true;
  const regexp =
    /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/;
  return regexp.test(val) || FormValidations.EMAIL.INVALID;
};

export const emailRules = [emailPattern];

/**
 * Password Rules
 */
export const pwdPattern = (val) => {
  const regexp =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,30}$/;
  return regexp.test(val) || FormValidations.PASSWORD.INVALID;
};

export const pwdSizeRequired = (val) => {
  const regexp = /^.{8,30}$/;
  return regexp.test(val) || FormValidations.PASSWORD.SIZE;
};

export const pwdAllowedChars = (val) => {
  const regexp = /^[A-Za-z\d@$!%*#?&]*$/;
  return regexp.test(val) || FormValidations.PASSWORD.ALLOWED_CHARS;
};

export const pwdOneLowerRequired = (val) => {
  const regexp = /[a-z]+/;
  return regexp.test(val) || FormValidations.PASSWORD.ONE_LOWER_REQUIRED;
};

export const pwdOneUpperRequired = (val) => {
  const regexp = /[A-Z]+/;
  return regexp.test(val) || FormValidations.PASSWORD.ONE_UPPER_REQUIRED;
};

export const pwdOneNumberRequired = (val) => {
  const regexp = /\d+/;
  return regexp.test(val) || FormValidations.PASSWORD.ONE_NUMBER_REQUIRED;
};

export const pwdSpecialCharRequired = (val) => {
  const regexp = /[@$!%*#?&]+/;
  return regexp.test(val) || FormValidations.PASSWORD.ONE_SPECIAL_CHAR_REQUIRED;
};

export const pwdRules = [pwdPattern];

export const pwdOneByOneRules = [
  pwdSizeRequired,
  pwdAllowedChars,
  pwdOneLowerRequired,
  pwdOneUpperRequired,
  pwdOneNumberRequired,
  pwdSpecialCharRequired,
];

/**
 * Phone Rules
 */
export const phonePattern = (val) => {
  if (!val) return true;
  const onlyNumPhone = val.replace(/\D+/g, "");
  const regexp = /^(\d){10,11}$/;
  const isValid = regexp.test(onlyNumPhone);
  return isValid || FormValidations.PHONE.INVALID;
};

export const phoneRules = [phonePattern];

/**
 * Cell Phone Rules
 */
export const cellPhonePattern = (val) => {
  if (!val) return true;
  const onlyNumCellPhone = val.replace(/\D+/g, "");
  const regexp = /^(\d){11}$/;
  const isValid = regexp.test(onlyNumCellPhone);
  return isValid || FormValidations.CELL_PHONE.INVALID;
};

export const cellPhoneRules = [cellPhonePattern];

/**
 * Username Rules
 */

export const usernamePattern = (val) => {
  if (!val) return true;
  const regexp = /^[A-Za-z0-9]{3,20}$/;
  return regexp.test(val) || FormValidations.USERNAME.INVALID;
};

export const usernameRules = [usernamePattern];

export const daysToExpireSizeRequired = (val) => {
  return (
    (val >= 0 && val <= 90) || FormValidations.WALLET_CONFIG_DAYS_TO_EXPIRE.SIZE
  );
};

/**
 * Zipcode Rules
 */
export const zipcodePattern = (val) => {
  if (!val) return true;
  const onlyNumZipcode = val.replace(/\D+/g, "");
  const regexp = /^(\d){8}$/;
  const isValid = regexp.test(onlyNumZipcode);
  return isValid || FormValidations.ZIPCODE.INVALID;
};

export const zipcodeRules = [zipcodePattern];

/**
 * Date Rules
 */
export const datePattern = (val) => {
  if (!val) return true;
  const regexp =
    /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;
  const isValid = regexp.test(val);
  return isValid || FormValidations.DATE.INVALID;
};

export const dateRules = [datePattern];

/**
 * Date Month Rules
 */
export const dateMonthPattern = (val) => {
  if (!val) return true;
  const regexp = /^(0[1-9]|10|11|12)\/(19|20)([0-9]){2}$/;
  const isValid = regexp.test(val);
  return isValid || FormValidations.DATE.INVALID;
};

export const dateMonthRules = [dateMonthPattern];

export default {
  required,
  searchSizeRequired,
  cpfPattern,
  cnpjPattern,
  documentPattern,
  emailPattern,
  pwdPattern,
  pwdSizeRequired,
  pwdAllowedChars,
  pwdOneLowerRequired,
  pwdOneUpperRequired,
  pwdOneNumberRequired,
  pwdSpecialCharRequired,
  phonePattern,
  cellPhonePattern,
  usernamePattern,
  daysToExpireSizeRequired,
  zipcodePattern,
  datePattern,
  dateMonthPattern,
};
