export const get = (key) => {
  const value = localStorage.getItem(key);
  try {
    return JSON.parse(value);
  } catch (e) {
    return value;
  }
};

export const set = (key, value) => {
  const data = value instanceof Object ? JSON.stringify(value) : value;
  localStorage.setItem(key, data);
};

export const remove = (key) => {
  localStorage.removeItem(key);
};

export const removeAll = () => {
  localStorage.clear();
};

export const getAccessModules = () => {
  const userProfiles = get("user-modules");
  if (userProfiles) {
    return userProfiles.modulos;
  }
  return [];
};
