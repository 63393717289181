/* eslint-disable no-undef */
import { Amplify, Auth } from "aws-amplify";

export const config = () => {
  return {
    aws_project_region: process.env.PROJECT_REGION,
    aws_cognito_region: process.env.COGNITO_REGION,
    aws_user_pools_id: process.env.USERS_POOLS_ID,
    aws_user_pools_web_client_id: process.env.USERS_POOLS_WEB_CLIENT_ID,
    oauth: {},
  };
};

export const instance = () => {
  Amplify.configure(config());
};

export const auth = Auth;
