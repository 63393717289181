export default {
  REQUIRED: "Obrigatório",
  SUBSCRIBERS: {
    REQUIRED:
      "Verifique se todos os assinantes possuem um espaço para assinatura!",
  },
  USERNAME: {
    INVALID: "Usuário inválido, deve conter somente letras e números",
  },
  CELL_PHONE: {
    INVALID: "Celular inválido",
  },
  CPF: {
    INVALID: "CPF inválido",
    UNAVAILABLE: "CPF indisponível",
  },
  CNPJ: {
    INVALID: "CNPJ inválido",
    UNAVAILABLE: "CNPJ indisponível",
  },
  DATE: {
    INVALID: "Data inválida",
  },
  DOCUMENT: {
    INVALID: "Documento inválido",
    UNAVAILABLE: "Documento indisponível",
  },
  EMAIL: {
    INVALID: "E-mail inválido",
  },
  CONFIRM_EMAIL: {
    NOT_MATCH: "O e-mail não está correto",
  },
  FILE: {
    INVALID: "Arquivo inválido",
  },
  PASSWORD: {
    SIZE: "Senha deve conter entre 8 e 30 caracteres",
    ALLOWED_CHARS: "Senha contem caracteres inválidos",
    ONE_LOWER_REQUIRED: "Senha deve conter uma letra minúscula",
    ONE_UPPER_REQUIRED: "Senha deve conter uma letra maiúscula",
    ONE_NUMBER_REQUIRED: "Senha deve conter um número",
    ONE_SPECIAL_CHAR_REQUIRED:
      "Senha deve conter um caractere especial (@$!%*#?&)",
    INVALID: "Senha inválida",
  },
  PHONE: {
    INVALID: "Telefone inválido",
  },
  SEARCH: {
    SIZE: "Se utilizado, deve conter pelo menos 3 caracteres",
  },
  ZIPCODE: {
    INVALID: "CEP inválido",
    UNAVAILABLE: "CEP indisponível",
    NOT_FOUND: "CEP não encontrado",
  },
  MIN: {
    STRING: "Campo deve ser pelo menos {min} caracteres",
  },
  WALLET_CONFIG_DAYS_TO_EXPIRE: {
    SIZE: "A data de vencimento deve conter entre 0 e 90 dias",
  },
};
