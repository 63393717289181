/* eslint-disable no-undef */
import axios from "axios";
import { get, remove } from "../storage";

const baseURL = process.env.BASE_URL;
const api = axios.create({ baseURL });

const requestInterceptor = (req) => {
  const accessToken = get("jwt");
  return accessToken
    ? {
        ...req,
        headers: {
          ...req.headers,
          common: { Authorization: `Bearer ${accessToken}` },
        },
      }
    : req;
};

const errorRequestInterceptor = (error) => {
  const errorMessage = error?.response?.data?.message;
  return errorMessage
    ? Promise.reject({ ...error, message: errorMessage })
    : Promise.reject(error);
};

const responseInterceptor = (res) => res;

const errorResponseInterceptor = (error) => {
  const { response } = error;
  const isUnauthorized = response?.status === 401;
  const isArrayBufferResponse =
    response?.config?.responseType === "arraybuffer";

  const hasErrorMessage = response?.data?.message;

  const redirectUrl = `${window.location.origin}/auth/login`;

  const removeJwtAndRedirect = () => {
    remove("jwt");
    setTimeout(() => {
      window.location = redirectUrl;
    }, 2000);
  };

  if (isUnauthorized) {
    removeJwtAndRedirect();
    return Promise.resolve();
  }

  if (hasErrorMessage) {
    return Promise.reject({
      ...error,
      message: hasErrorMessage,
    });
  }
  const parseJsonError = () => {
    try {
      const decoded = new TextDecoder().decode(response.data);
      const message = JSON.parse(decoded).message;
      return Promise.reject({ ...error, message });
    } catch (error) {
      return Promise.reject({
        ...error,
        message: "Failed to parse error message",
      });
    }
  };

  if (isArrayBufferResponse) {
    return parseJsonError();
  }

  return Promise.reject({ ...error });
};

api.interceptors.request.use(requestInterceptor, errorRequestInterceptor);
api.interceptors.response.use(responseInterceptor, errorResponseInterceptor);

export default api;
