import i18n from "../i18n/pt-Br";

export const promptCancel = {
  message: i18n.ALERT.MSGA006,
  cancel: {
    flat: true,
    label: i18n.FORM.ACTION.NO,
    color: "grey-14",
  },
  ok: {
    flat: true,
    label: i18n.FORM.ACTION.YES,
  },
  persistent: true,
};

export const promptDelete = {
  message: i18n.ALERT.MSGA005,
  cancel: {
    flat: true,
    label: i18n.FORM.ACTION.NO,
    color: "grey-14",
  },
  ok: {
    flat: true,
    label: i18n.FORM.ACTION.YES,
    color: "red-12",
  },
  persistent: true,
};

export const promptDisassociate = {
  message: i18n.ALERT.MSGA018,
  cancel: {
    flat: true,
    label: i18n.FORM.ACTION.NO,
    color: "grey-14",
  },
  ok: {
    flat: true,
    label: i18n.FORM.ACTION.YES,
    color: "red-12",
  },
  persistent: true,
};

const prompt = (attrs) => ({
  title: attrs?.title,
  message: attrs?.message,
  cancel: {
    flat: true,
    label: i18n.FORM.ACTION.CANCEL,
    color: "grey-14",
  },
  persistent: true,
});

export const promptConfirm = (attrs) => ({
  ...prompt(attrs),
  ok: {
    flat: true,
    label: i18n.FORM.ACTION.CONFIRM,
  },
});

export const promptReasonAndSave = () => ({
  title: i18n.ALERT.MSGAN001,
  prompt: {
    model: "",
    type: "textarea",
    isValid: (val) => !!val,
    maxlength: 400,
  },
  cancel: {
    flat: true,
    label: i18n.FORM.ACTION.CANCEL,
    color: "grey-14",
  },
  ok: {
    flat: true,
    label: i18n.FORM.ACTION.SAVE,
  },
  persistent: true,
});

const promptRadio = (attrs) => ({
  title: attrs?.title,
  message: attrs?.message,
  options: {
    type: "radio",
    model: null,
    isValid: (val) => !!val,
    items: attrs?.items,
  },
  cancel: {
    flat: true,
    label: i18n.FORM.ACTION.CANCEL,
    color: "grey-14",
  },
  persistent: true,
});

export const promptRadioAndConfirm = (attrs) => ({
  ...promptRadio(attrs),
  ok: {
    flat: true,
    label: i18n.FORM.ACTION.CONFIRM,
  },
});

export const promptRadioAndSave = (attrs) => ({
  ...promptRadio(attrs),
  ok: {
    flat: true,
    label: i18n.FORM.ACTION.SAVE,
  },
});

export default {
  promptCancel,
  promptDelete,
  promptConfirm,
  promptReasonAndSave,
  promptRadioAndConfirm,
  promptRadioAndSave,
};
