export default {
  LABELS: {
    POSTALCODE: "CEP",
    STREET: "Endereço | Endereços",
    NUMBER: "Número | Números",
    COMPLEMENT: "Complemento | Complementos",
    NEIGHBORHOOD: "Bairro | Bairros",
    CITY: "Cidade | Cidades",
    STATE: "UF | UFs",
    PHONENUMBER: "Telefone | Telefones",
  },
};
