import i18n from "../i18n/pt-Br";

export default {
  rowKey: "id",
  loadingLabel: i18n.TABLE.LOADING,
  noDataLabel: i18n.TABLE.NO_DATA,
  noResultsLabel: i18n.TABLE.NO_RESULT,
  rowsPerPageLabel: i18n.TABLE.ROWS_PER_PAGE,
  rowsPerPageOptions: [10, 25, 50, 100],
};
