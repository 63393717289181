export const optionValidator = (val) =>
  Object.prototype.hasOwnProperty.call(val, "label") &&
  Object.prototype.hasOwnProperty.call(val, "value");

export const optionsValidator = (val) =>
  val.map(optionValidator).every((v) => v === true);

export const columnValidator = (val) =>
  Object.prototype.hasOwnProperty.call(val, "name") &&
  Object.prototype.hasOwnProperty.call(val, "label") &&
  Object.prototype.hasOwnProperty.call(val, "field");

export const columnsValidator = (val) =>
  val.map(columnValidator).every((v) => v === true);

export const ruleValidator = (val) => typeof val === "function";

export const rulesValidator = (val) => val.every(ruleValidator);

export const stringValidator = (val) => typeof val === "string";

export const arrayOfStringValidator = (val) => val.every(stringValidator);

export default {
  optionValidator,
  optionsValidator,
  columnValidator,
  columnsValidator,
  ruleValidator,
  rulesValidator,
  stringValidator,
  arrayOfStringValidator,
};
